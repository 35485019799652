<template lang="pug">
    .main-wrapper.unidades-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "unidades-medclub" }'> Unidades</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } Unidade`")
                        .p-grid.p-fluid.p-align-end(style='margin: 0 auto; max-width: 500px;')

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.nm_unidade.$error }")
                                label.form-label Nome:
                                InputText(type='text' v-model='$v.model.nm_unidade.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_unidade.$error')
                                    .form-message--error(v-if="!$v.model.nm_unidade.minLength") <b>Nome</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_unidade.required") <b>Nome</b> é obrigatório.

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_estabelecimento.$error }")
                                label.form-label Estabelecimento:
                                ProgressBar(v-if="options.estabelecimentos.length == 0" mode="indeterminate")
                                Dropdown(v-else v-model='$v.model.cd_estabelecimento.$model' :options='options.estabelecimentos'
                                    optionLabel='text' optionValue='value' placeholder='Selecione' filter)
                                .feedback--errors(v-if='submitted && $v.model.cd_estabelecimento.$error')
                                    .form-message--error(v-if="!$v.model.cd_estabelecimento.required") <b>Estabelecimento</b> é obrigatório.

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_estado.$error }")
                                label.form-label Estado:
                                ProgressBar(v-if="options.ufs.length == 0" mode="indeterminate")
                                Dropdown(v-else v-model='$v.model.cd_estado.$model' :options='options.ufs' @change='model.cd_cidade = null; getMunicipiosEndereco()'
                                    optionLabel='textAlt' optionValue='value' placeholder='Selecione' filter)
                                .feedback--errors(v-if='submitted && $v.model.cd_estado.$error')
                                    .form-message--error(v-if="!$v.model.cd_estado.required") <b>Estado</b> é obrigatório.

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_cidade.$error }")
                                label.form-label Município:
                                Dropdown(v-model='$v.model.cd_cidade.$model' :options='options.municipiosEndereco' :disabled="!model.cd_estado" filter
                                    optionLabel='text' optionValue='value' placeholder='Selecione' v-tooltip.bottom="model.cd_estado == null ? 'Informe o estado antes' : ''")
                                .feedback--errors(v-if='submitted && $v.model.cd_cidade.$error')
                                    .form-message--error(v-if="!$v.model.cd_cidade.required") <b>Município</b> é obrigatório.

                            .p-col-6.p-md-4.ta-center
                                label.form-label Restrito ao sistema:
                                InputSwitch(v-model='model.ie_restrito')

                            .p-col-6.p-md-4.ta-center
                                label.form-label Habilitado:
                                InputSwitch(v-model='model.ie_status')

                            .p-col-12.mt-2.mb-2(v-if="model.id")
                                .p-grid
                                    .p-col-12.p-md-6
                                        span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6
                                        span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12
                                Button(label='Salvar' type="submit")


</template>

<style lang="scss">
    .unidades-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import InputSwitch from 'primevue/inputswitch'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'

    import { UnidadesMedclub, Utils, Estabelecimento } from './../../middleware'
    import { required, minLength } from 'vuelidate/lib/validators'
    import moment from "moment"

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password,
                InputMask, InputSwitch, SelectButton, Dropdown, Tooltip
        },
        directives: { tooltip: Tooltip },
        created () {
            Utils.getUFs().then(response => {
                this.getEstabelecimentos()

                this.options.ufs.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
                if (response.status == 200)
                    response.data.forEach(uf => this.options.ufs.push({ value: uf.id, text: uf.ds_sigla, textAlt: uf.nm_estado }))

                let id = 0
                if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
                if (id != 0) {
                    UnidadesMedclub.find(id).then(response => {
                        if (response.status == 200) {
                            let keys = Object.keys(this.model)

                            keys.forEach(key => this.model[key] = response.data[key])

                            this.model.id = id;
                            this.model.cd_estado = this.model.cd_cidade.cd_estado
                            this.model.cd_cidade = this.model.cd_cidade.id

                            this.model.nm_usuario_cri = response.data.nm_usuario_cri
                            this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                            if (response.data.dt_atualizado) {
                                this.model.nm_usuario_edi = response.data.nm_usuario_edi
                                this.model.dt_atualizado_f = moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm')
                            }

                            this.getMunicipiosEndereco()
                        }
                        this.waiting = false
                    })
                } else this.waiting = false
            })
        },
        data () {
            return {
                model: {
                    nm_unidade: '',
                    cd_cidade: null,
                    cd_estado: null,
                    ie_restrito: false,
                    cd_estabelecimento: null,
                    ie_status: true,
                    ie_classificacao: "UNI",
                },
                options: {
                    municipiosEndereco: [],
                    ufs: [],
                    estabelecimentos: [],
                },
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    nm_unidade: { required, minLength: minLength(2) },
                    cd_cidade: { required },
                    cd_estado: { required },
                    cd_estabelecimento: { required },
                }
            }

            return v
        },
        methods: {
            getMunicipiosEndereco () {
                this.options.municipiosEndereco = []
                if (this.model.cd_estado) {
                    let ufSelected = this.options.ufs.find(uf => uf.value == this.model.cd_estado)
                    Utils.getMunicipiosByUF(ufSelected.text).then(response => {
                        this.options.municipiosEndereco.push({ value: null, text: '- Selecione -' })
                        if (response.status == 200)
                            response.data.forEach(mun => this.options.municipiosEndereco.push({ value: mun.id, text: mun.nm_cidade }))
                    })
                }
            },
            getEstabelecimentos(){
                Estabelecimento.findAllClean({order: 'nm_fantasia'}).then(response => {
                    this.options.estabelecimentos.push({ value: null, text: '- Selecione -' })
                    if (response.status === 200) {
                        response.data.forEach(item => 
                            this.options.estabelecimentos.push({text: item.nm_fantasia, value: item.id})
                        )
                    }
                })
            },
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)

                this.waitingForm = true
                UnidadesMedclub.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'unidades-medclub' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                    this.waitingForm = false
                })

            }
         }
      }
</script>
